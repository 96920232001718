.hwrap {
	margin: 0px;
	flex: 1;
	display: flex;
	flex-direction: column;
	align-self: center;
	box-sizing: border-box;
	/* font-size: 2.5rem; */
}

.butGroup {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-around;
	/* gap: 50px; */
	/* width: 90%; */
	margin: 20px;
}

.hr {
	width: 10%;
	align-self: center;
	margin: 7px;
}
.describe1 {
	max-width: 850px;
	font-size: 18px;
	align-self: center;
	text-align: left;
	display: flex;
	flex-direction: column;
}
.h2 {
	padding: 2px;
	margin: 4px;
}

.describe {
	max-width: 850px;
	margin: 15px;
	font-size: 16px;
	align-self: center;
	text-align: left;
	display: flex;
	flex-direction: column;
}

.title {
	font-size: 24px;
}

.slogan {
	font-size: 18px;
	/* maek font italic */
	font-style: italic;
	margin: 3px;
	max-width: 90%;
	align-self: center;
}

.carousel {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.startWrap1 {
	display: flex;
	flex-direction: column;
	background-color: white !important;
	width: 100%;
	box-sizing: border-box;
	margin: 0px;
}

.summary > li {
	margin-left: 20px;
}

.leftSec {
	display: flex;
	/* flex-direction: column; */
	align-items: center;
	box-sizing: border-box;
	max-width: 100vw;
	box-sizing: border-box;
}

.rightSec {
	display: flex;
	align-self: flex-start;
	justify-content: space-around;
	flex-direction: column;
	flex: 1 0 51%;
	/* border: 1px solid red; */
	width: 100%;
	box-sizing: border-box;
	margin: 0px;
}

.startWrap {
	background: -webkit-linear-gradient(to bottom, #cbcaa5, #334d50);
	/* Chrome 10-25, Safari 5.1-6 */
	background: linear-gradient(to bottom, #cbcaa5, #334d50);

	padding: 5px;
	box-sizing: border-box;
	margin: 0px;

	width: 100%;
	background-color: #354147dd;
}

.startWrap > * {
	text-align: left;
	margin: 10px;
	padding: 5px;
	color: white;
}

.bookBut {
	padding: 20px;
	margin: 10px;
	flex: 1 0 34%;
	text-align: center;
	font-weight: bold;
	font-size: 25px;
	max-width: 700px;
	background: #334d50;
	/* fallback for old browsers */
	background: -webkit-linear-gradient(to bottom, #cbcaa5, #334d50);
	/* Chrome 10-25, Safari 5.1-6 */
	background: linear-gradient(to bottom, #cbcaa5, #334d50);
	/* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
	text-decoration: none;

	cursor: pointer;
	color: white;
	border-end-end-radius: 10px;
	border-top-left-radius: 10px;
	border-end-start-radius: 10px;
	border: 1px solid white;
	box-shadow: 2px 2px 3px #354147;
	transition: all 0.3s ease;
}

.bookBut:hover {
	border: 1px solid black;
}

.mapLink {
	border: 2px solid #354147;
	flex: 1 0 34%;
	padding: 20px;
	width: 20%;
	font-size: 25px;
	margin: 10px;
	max-width: 700px;
	text-align: center;
	border-end-end-radius: 10px;
	border-top-left-radius: 10px;
	border-end-start-radius: 10px;

	color: white;
	background-color: #354147dd;
	border: 1px solid transparent;
	box-shadow: 2px 2px 3px transparent;
}

.coverWrap {
	max-width: 100vw;
	margin: 7px;
	box-sizing: border-box;
	position: relative;
	aspect-ratio: 16 / 9; /* Set a fixed aspect ratio */
	overflow: hidden; /* Ensure content doesn't overflow */
}

.coverImage {
	width: 100%;
	height: 100%;
	object-fit: cover; /* This will maintain aspect ratio while filling the container */
	object-position: center; /* Center the image within the container */
	transition: all 0.3s ease;
	border: 7px solid #334d50;
	padding: 6px;
	background-color: white;
	cursor: pointer;
	box-sizing: border-box;
}

.mapImage {
}

.house1Image {
}

.thumbDiv {
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	align-items: center;

	background-color: transparent;
	/* border: 1px solid red; */
	gap: 5px;
	flex-wrap: wrap;
	align-self: center;
	box-sizing: border-box;
	padding: 0px;
	margin: 0px;
	height: 100%;
	/* border: 1px solid red; */
}

.thumbnail:hover {
	transform: scale(1.05);
	box-shadow: 0px 15px 25px rgba(0, 0, 0, 0.2);
	border-bottom: 7px solid rgb(33, 86, 199);
	cursor: pointer;
}

.thumbnail {
	flex: 1;
	padding: 0px;
	box-sizing: border-box;
	margin: 0px;
	border: 1px solid rgb(219, 219, 219);

	padding: 0px;
	aspect-ratio: 16 / 9;
	border-radius: 5px;
	border-bottom: 3px solid #35414767;
}

.thumbnailActive {
	flex: 1;
	aspect-ratio: 16/9;
	padding: 0px;
	box-sizing: border-box;
	margin: 0px;
	padding: 0px;
	border-radius: 5px;
	border: 1px solid rgb(219, 219, 219);

	border-bottom: 3px solid rgb(33, 86, 199);
	box-shadow: 0px 15px 25px rgba(0, 0, 0, 0.2);
	/* filter: brightness(1.05); */
	/* z-index: 0; */
}

.thumbdivone {
	/* flex: 1 0 10%; */
	width: 160px;
	display: flex;
	margin: 0px;
	padding: 2px;
	align-items: center;
	justify-content: center;
}

.pauseButton {
	position: absolute;
	top: 20px;
	right: 20px;
	padding: 5px 10px;
	background-color: rgba(0, 0, 0, 0.5);
	color: white;
	border: none;
	border-radius: 7px;
	cursor: pointer;
	z-index: 10;
	display: flex;
	align-items: center;
	justify-content: center;
	transition: background-color 0.3s ease;
}

.pauseButton:hover {
	background-color: rgba(0, 0, 0, 0.7);
}

.pauseButton:focus {
	outline: none;
	box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.5);
}

.icon {
	width: 24px;
	height: 24px;
	fill: white;
	margin-right: 5px;
}

.buttonText {
	font-size: 14px;
	font-weight: bold;
}

/* css tablet */

@media screen and (max-width: 1200px) {
	.butGroup {
		flex-direction: column;
		align-items: center;
		gap: 5px;
	}
	.describe {
		max-width: 650px;
	}
	.hr {
		width: 70%;
		align-self: center;
		margin: 10px;
	}

	.hwrap {
		align-items: center;
	}

	.startWrap1 {
		flex-direction: column;
		align-items: center;
	}

	.coverImage {
		/* flex: 1 0 51%; */
		/* width: calc(95vw); */
		/* height: calc(95vw/ 2* 1.25); */
		padding: 5px;
		max-height: 100vh;
	}

	.bookBut {
		align-self: center;
		width: 80%;
		font-size: 1.5rem;
		padding: 15px;
		box-sizing: border-box;
	}

	.mapLink {
		align-self: center;
		text-decoration: none;
		width: 80%;
		font-size: 18px;
		text-align: center;
		padding: 15px;
		font-weight: 400;
	}

	.thumbdivone {
		width: 110px;
	}
}

@media screen and (max-width: 900px) {
	.leftSec {
		/* display: flex; */
		flex-direction: column;
	}

	.thumbDiv {
		flex-direction: row;
		gap: 5px;
		justify-content: center;
	}

	.thumbdivone {
		width: 90px;
		display: flex;
		margin: 0px;
		padding: 2px;
		align-items: center;
		justify-content: center;
	}
}

/* css phone */
@media screen and (max-width: 800px) {
	.bookBut {
		align-self: center;
		width: 100%;
		flex: 1 0 51%;
		font-size: 18px;
		padding: 20px;
	}

	.pauseButton {
		right: 10px;
		padding: 3px 8px;
	}

	.icon {
		width: 20px;
		height: 20px;
		fill: white;
		margin-right: 5px;
	}

	.buttonText {
		font-size: 12px;
	}

	.mapLink {
		align-self: center;
		/* font-size: 1.3rem; */
		text-decoration: none;
		padding: 25px;
		border-radius: 10px;
	}

	.rightSec {
		flex-direction: column;
	}

	.thumbnail {
		flex: 1 0 10%;
		/* margin-top: 10px; */
		/* max-width: 20vw; */
		aspect-ratio: 16/9;
	}

	.thumbnailActive {
		flex: 1 0 10%;
		aspect-ratio: 16/9;
	}

	.slogan {
		font-size: 16px;
		/* maek font italic */
		font-style: italic;
		align-self: center;
		max-width: 99%;
	}

	.startWrap {
		align-items: center;
		display: flex;
		align-items: center;
		text-align: center;
		justify-content: center;
	}

	.thumbdivone {
		width: 80px;
	}

	.title {
		font-size: 18px;
	}

	.thumbDiv {
		flex-direction: row;
		gap: 2px;
		justify-content: center;
		width: 100%;
		display: flex;
		flex-wrap: wrap;
		padding: 2px;
	}

	.thumbdivone {
		flex: 0 0 23%;
		width: 23%;
		margin: 1px;
		padding: 1px;
	}

	.thumbnail,
	.thumbnailActive {
		width: 100%;
		height: auto;
		aspect-ratio: 16/9;
	}
}

@media screen and (max-width: 400px) {
	.thumbdivone {
		flex: 0 0 23%;
		width: 23%;
		margin: 1px;
		padding: 0.5px;
	}
}

.navButton {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	background-color: rgba(0, 0, 0, 0.5);
	color: white;
	border: none;
	border-radius: 7px;
	width: 40px;
	height: 40px;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	transition: background-color 0.3s ease;
}

.navButton:hover {
	background-color: rgba(0, 0, 0, 0.7);
}

.prevButton {
	left: 10px;
}

.nextButton {
	right: 10px;
}

/* Adjust for mobile screens */
@media screen and (max-width: 800px) {
	.navButton {
		width: 30px;
		height: 50px;
	}

	.prevButton {
		left: 5px;
	}

	.nextButton {
		right: 5px;
	}
}

/* Add a new media query for very small screens */
@media screen and (max-width: 400px) {
  .thumbdivone {
    min-width: 40px; /* Reduce minimum width for very small screens */
  }
}

/* Add these new styles at the end of your CSS file */

.fullscreenButton {
  position: absolute;
  bottom: 20px;
  right: 20px;
  padding: 5px 10px;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  border-radius: 7px;
  cursor: pointer;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease;
}

.fullscreenButton:hover {
  background-color: rgba(0, 0, 0, 0.7);
}

.fullscreenButton:focus {
  outline: none;
  box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.5);
}

.fullscreenImage {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.9);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.fullscreenImage img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.fullscreenImage .pauseButton,
.fullscreenImage .navButton,
.fullscreenImage .fullscreenButton {
  position: fixed;
  z-index: 1001;
  padding: 5px 10px;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  border-radius: 7px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease;
  font-size: 14px; /* Add this line to set a consistent font size */
}

.fullscreenImage .pauseButton:hover,
.fullscreenImage .navButton:hover,
.fullscreenImage .fullscreenButton:hover {
  background-color: rgba(0, 0, 0, 0.7);
}

.fullscreenImage .pauseButton {
  top: 30%;
  right: 20px;
  transform: translateY(-50%);
}

.fullscreenImage .fullscreenButton {
  top: 70%;
  right: 20px;
  transform: translateY(-50%);
}

.fullscreenImage .prevButton {
  left: 20px;
  top: 50%;
  transform: translateY(-50%);
}

.fullscreenImage .nextButton {
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
}

/* Add these new styles for the fullscreen buttons */
.fullscreenImage .pauseButton,
.fullscreenImage .fullscreenButton {
  width: auto; /* Allow the width to adjust to content */
  height: auto; /* Allow the height to adjust to content */
  max-width: 120px; /* Set a maximum width */
  max-height: 40px; /* Set a maximum height */
}

.fullscreenImage .navButton {
  width: 40px;
  height: 40px;
}

/* Adjust for mobile screens */
@media screen and (max-width: 800px) {
  .fullscreenImage .pauseButton,
  .fullscreenImage .navButton,
  .fullscreenImage .fullscreenButton {
    padding: 3px 8px;
    font-size: 12px;
  }

  .fullscreenImage .pauseButton,
  .fullscreenImage .fullscreenButton {
    max-width: 100px;
    max-height: 30px;
  }

  .fullscreenImage .navButton {
    width: 30px;
    height: 30px;
  }
}