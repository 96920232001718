.content {
	height: 800px;
}

.button {
	padding: 10px;
	background-color: transparent;
	font-weight: bold;
	border: 1px solid black;
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 5px;
	margin: 10px;
	border-radius: 5px;
	cursor: pointer;
	width: 300px;
	margin: 20px;
}

.button:hover {
	background-color: black;
	color: white;
}
