.sellLandContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 100%; /* Changed from 1200px to 100% */
  margin: 0 auto;
  padding: 20px;
  background-color: #f5f5f7;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Helvetica', 'Arial', sans-serif;
  color: #1d1d1f;
  box-sizing: border-box; /* Add this to include padding in width calculation */
max-width: 1200px;
}

.sellLandContainer h1 {
  font-size: 36px; /* Reduced from 48px */
  font-weight: 700;
  color: #1d1d1f;
  margin-bottom: 30px;
  text-align: center;
  width: 100%;
}

.introSection {
  max-width: 100%; /* Changed from 800px to 100% */
  margin-bottom: 30px;
}

.introSection p {
  font-size: 18px; /* Reduced from 21px */
  line-height: 1.5;
  text-align: center;
}

.sellLandContainer h2 {
  font-size: 28px; /* Reduced from 32px */
  font-weight: 600;
  color: #1d1d1f;
  margin-top: 40px; /* Reduced from 50px */
  margin-bottom: 20px; /* Reduced from 25px */
  text-align: center;
  width: 100%;
}

.sellLandContainer p {
  font-size: 17px;
  line-height: 1.6;
  color: #1d1d1f;
  margin-bottom: 20px;
  text-align: center;
}

.sellLandContainer ul {
  list-style-type: none;
  padding-left: 0;
  margin-bottom: 30px;
  text-align: left; /* Align bullet points to the left */
}

.sellLandContainer li {
  font-size: 17px;
  line-height: 1.6;
  color: #1d1d1f;
  margin-bottom: 15px;
  padding-left: 30px;
  position: relative;
  text-align: left; /* Ensure list items are left-aligned */
}

.sellLandContainer li::before {
  content: none;
}

.howItWorks {
  display: flex;
  flex-wrap: nowrap; /* Changed from wrap to nowrap */
  justify-content: space-between;
  gap: 20px;
  width: 100%;
  margin-bottom: 40px;
}

.step {
  flex: 1; /* Changed from flex-basis to flex */
  min-width: 0; /* Add this to allow shrinking below content size */
  background-color: #ffffff;
  padding: 20px; /* Reduced padding */
  border-radius: 12px;
  text-align: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.step:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.stepNumber {
  font-size: 28px; /* Reduced from 36px */
  font-weight: 700;
  color: #0071e3;
  margin-bottom: 10px; /* Reduced from 15px */
}

.step h3 {
  font-size: 18px; /* Reduced from 21px */
  font-weight: 600;
  margin-bottom: 8px; /* Reduced from 10px */
  color: #1d1d1f;
}

.step p {
  font-size: 14px; /* Reduced from 16px */
  color: #1d1d1f;
  line-height: 1.4;
}

@media screen and (max-width: 768px) {
  .howItWorks {
    flex-direction: column;
    flex-wrap: wrap;
  }

  .step {
    flex-basis: 100%;
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 480px) {
  .step {
    padding: 15px;
  }

  .stepNumber {
    font-size: 24px;
  }

  .step h3 {
    font-size: 16px;
  }

  .step p {
    font-size: 12px;
  }
}

.contactInfo {
  background: linear-gradient(135deg, #0071e3, #42a1ec);
  padding: 30px;
  border-radius: 18px;
  margin-top: 50px;
  width: 100%;
  box-sizing: border-box;
  text-align: center;
}

.contactInfo h2 {
  color: white;
  margin-top: 0;
}

.contactInfo p {
  color: white;
  margin-bottom: 10px;
  font-size: 18px;
}

.contactFormWrapper {
  max-width: 600px;
  width: 90%; /* Add this line to ensure it's not full width on smaller screens */
  padding: 20px;
  background-color: white;
  border-radius: 18px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin: 0 auto;
  margin-top: 30px; /* Add some top margin */
  margin-bottom: 30px; /* Add some bottom margin */
}

.contactForm {
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%; /* Change from max-width to width */
}

/* Add these new styles for form inputs and labels */
.contactForm label {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  width: 100%;
}

.contactForm input,
.contactForm textarea {
  padding: 10px;
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
  box-sizing: border-box; /* Ensure padding doesn't affect overall width */
}

.contactForm textarea {
  height: 100px;
  resize: vertical;
}

/* Adjust button styles */
.subButton {
  align-self: stretch; /* Changed from flex-start to stretch */
  padding: 12px 20px; /* Increased vertical padding */
  font-size: 18px; /* Increased font size */
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
  width: 100%; /* Make the button full width */
  margin-top: 10px; /* Add some top margin */
}

.subButton:hover {
  background-color: #0056b3;
}

/* You can keep the existing media query for very small screens */
@media screen and (max-width: 480px) {
  .subButton {
    font-size: 16px; /* Slightly smaller font size for very small screens */
    padding: 10px 15px; /* Slightly reduced padding for very small screens */
  }
}

.errorMessage {
  color: red;
  margin-top: 1rem;
  text-align: center;
}

@media screen and (max-width: 768px) {
  .sellLandContainer h1 {
    font-size: 36px;
  }

  .introSection p {
    font-size: 18px;
  }

  .sellLandContainer h2 {
    font-size: 28px;
  }

  .howItWorks {
    flex-direction: column; /* Stack vertically on smaller screens */
  }

  .step {
    margin-bottom: 20px;
  }

  .featureGrid {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  }
}

@media screen and (max-width: 480px) {
  .sellLandContainer h1 {
    font-size: 32px;
  }

  .introSection p {
    font-size: 16px;
  }

  .sellLandContainer h2 {
    font-size: 24px;
  }

  .step {
    flex-basis: 100%;
  }

  .featureGrid {
    grid-template-columns: 1fr;
  }
}

.messageGuidance {
  font-size: 12px; /* Reduced from 14px */
  color: #666;
  margin-bottom: 5px; /* Reduced from 10px */
  line-height: 1.3; /* Reduced from 1.4 */
}

.featureGrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr)); /* Changed from 250px to 150px */
  gap: 15px; /* Reduced from 20px */
  margin-bottom: 30px; /* Reduced from 40px */
  width: 100%;
}

.featureItem {
  background-color: #f5f5f5;
  padding: 15px; /* Reduced from 20px */
  border-radius: 8px;
  text-align: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.featureItem:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.featureIcon {
  font-size: 24px;
  margin-bottom: 10px;
  color: #0071e3;
}

.featureItem p {
  font-size: 14px;
  line-height: 1.4;
  margin: 0;
}

@media screen and (max-width: 1200px) {
  .sellLandContainer {
    max-width: 100%;
  }
}

@media screen and (max-width: 768px) {
  .featureGrid {
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  }
}

@media screen and (max-width: 480px) {
  .featureGrid {
    grid-template-columns: 1fr 1fr;
  }
}

.sectionIntro {
  font-size: 18px;
  line-height: 1.6;
  color: #1d1d1f;
  margin-bottom: 20px;
  text-align: center;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

.styledList {
  list-style-type: none;
  padding-left: 0;
  margin-bottom: 30px;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

.styledList li {
  font-size: 18px;
  line-height: 1.6;
  color: #1d1d1f;
  margin-bottom: 15px;
  padding-left: 30px;
  position: relative;
  text-align: left;
}

.styledList li::before {
  content: "•";
  color: #0071e3;
  font-size: 24px;
  position: absolute;
  left: 0;
}

@media screen and (max-width: 768px) {
  .sectionIntro,
  .styledList li {
    font-size: 16px;
  }
}

@media screen and (max-width: 480px) {
  .sectionIntro,
  .styledList li {
    font-size: 14px;
  }
}

@media screen and (max-width: 480px) {
  .sellLandContainer {
    padding: 10px;
  }

  .sellLandContainer h1 {
    font-size: 28px;
  }

  .introSection p {
    font-size: 16px;
  }

  .sellLandContainer h2 {
    font-size: 24px;
  }

  .featureGrid {
    grid-template-columns: 1fr;
  }

  .featureItem {
    padding: 10px;
  }

  .contactFormWrapper {
    padding: 10px;
  }

  .contactForm input,
  .contactForm textarea {
    padding: 8px;
  }
}
