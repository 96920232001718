.navWrapNormal {
  display: flex;
  /* align-self: flex-end; */
  justify-content: space-between;
  align-items: center;
  align-self: center;
  width: 95%;

  max-width: 50%;
  /* right: 0; */
  border-top: 2px solid white;
  margin: 5px;
  /* box-shadow: 0px 3px 2px #354147; */
  z-index: 99999;
  bottom: 0;
  border-radius: 10px;
  position: fixed;
  padding: 2px;
  /* left: 0; */
  background: #334d50;
  /* fallback for old browsers */
  background: -webkit-linear-gradient(to left, #cbcaa5c8, #334d50c8);
  background: linear-gradient(to left, #cbcaa5cf, #334d50ca);
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  box-sizing: border-box;
}

.navWrap>* {
  color: white;

}

.logoarea {
  display: flex;
  align-items: center;
  text-decoration: none;
}

.phone {
  text-decoration: none;
  font-weight: bold;
  color: white;
  cursor: pointer;
  transition: 0.2s;
  padding: 2px;
}

.phone:hover {
  border-radius: 5px;
}


.navWrap>p {
  font-weight: bold;
}

.logo {
  margin: 0px;
  width: 35px;
  height: 35px;
  border-radius: 10px;
  margin: 2px;
  border: 1px solid white;
  padding: 5px;
}

.menuButton {
  flex: 1 0 16%;
  border-right: 1px solid white;
  padding: 1px;
  margin: 0px;
  font-size: 15px;
  background-color: transparent;
  font-weight: bolder;
  display: flex;
  justify-content: center;
  color: white;
  text-decoration: none;
  /* border: 1 px solid red; */
}

.menuButton1 {
  flex: 1 0 16%;
  /* border: 1px solid white; */
  border: 0px;

  background-color: transparent;
  font-weight: bolder;
  font-size: 18px;
  text-align: center;
  /* padding: 15px; */
  width: 100%;
  color: white;
  text-decoration: none;
}

.icon {
  width: 35px;
  height: 35px;
  border-bottom: 1px solid white;
  padding: 0px;
  margin: 0px;
  filter: brightness(0) invert(0.1);
  transition: all 0.3s;
}

.icon:hover {
  filter: brightness(0) invert(0.9);
  border-bottom: 1px solid white;
}

.langButton {
  flex: 1 0 16%;
  padding: 1px;
  margin: 0px;
  background-color: transparent;
  font-weight: bolder;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  color: white;
  text-decoration: none;
  transition: all 0.3s ease;
}

.langButton:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.flagIcon {
  width: 20px;
  height: 20px;
  filter: grayscale(100%) brightness(100%);
  transition: all 0.3s ease;
}

.langButton:hover .flagIcon {
  filter: grayscale(0%);
}

/* Mobile adjustments */
@media screen and (max-width: 800px) {
  .langButton {
    padding: 1px;
  }

  .flagIcon {
    width: 16px;
    height: 16px;
  }

  .langButton span {
    font-size: 14px;
  }
}

@media screen and (max-width: 1400px) {
  .navWrapNormal {
    display: flex;
    padding: 2px;
    margin: 0px;
    justify-content: space-between;
    align-items: center;
    align-self: center;
    max-width: 90%;
    width: 90%;
    margin: 2px;
    z-index: 99999;
    bottom: 0;
    border-top: 1px solid white;
    border-radius: 10px;
    box-sizing: border-box;
  }

  .icon {
    width: 35px;
    height: 35px;
    padding: 2px;
    margin: 2px;
  }

  .logo {
    margin: 0px;
    width: 35px;
    height: 35px;
    border-radius: 10px;
    margin: 0px;
    border: 1px solid white;
    padding: 4px;
  }
}

@media screen and (max-width: 900px) {
  .navWrapNormal {
    /* border: 1px solid red; */
    /* padding: 2px; */
  }

  .menuButton {
    margin: 1px;
  }



  .logo {
    width: 30px;
    height: 30px;
    /* padding: 4px; */
  }
}