.navWrap {
  display: flex;
  z-index: 99999;
  top: 0;
  width: 100%;
  flex: 1 0 51%;
  width: 100%;

  max-width: 100%;
  position: fixed;

}

.navWrapNormal {
  display: flex;
  z-index: 99999;
  top: 0;
  width: 100%;
  flex: 1 0 51%;
  width: 100%;

  max-width: 100%;
}

.content {
  border-radius: 10px;
  background: #334d50;
  background: -webkit-linear-gradient(to left, #cbcaa5, #334d50);
  background: linear-gradient(to left, #cbcaa5, #334d50);
  justify-content: space-between;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
  margin: 5px;
  flex: 1 0 51%;
  box-shadow: 0px 3px 2px #354147;
  box-sizing: border-box;
}

.content1 {
  border-radius: 10px;
  background: #334d50;
  background: -webkit-linear-gradient(to left, #cbcaa5c8, #334d50c8);
  background: linear-gradient(to left, #cbcaa5cf, #334d50ca);
  justify-content: space-between;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
  margin: 5px;
  flex: 1 0 51%;
  box-shadow: 0px 3px 2px #354147;
  box-sizing: border-box;
}



.navWrap>* {
  color: white;

}

.logoarea {
  display: flex;
  align-items: center;
  text-decoration: none;
}

.phone {
  text-decoration: none;
  font-weight: bold;
  color: white;
  cursor: pointer;
  transition: 0.2s;
  padding: 5px;
}

.phone:hover {
  border: 1px solid #383e42;
  border-radius: 5px;
}


.navWrap>p {
  font-weight: bold;
}

.logo {
  margin: 0px;
  width: 50px;
  height: 50px;

  border-radius: 10px;
  margin: 5px;
  border: 2px solid white;
  padding: 4px;
}

.menuButton {
  padding: 12px;
  font-size: 15px;
  border-radius: 5px;
  border: 1px solid white;
  background-color: transparent;
  font-weight: bolder;
  margin: 15px;
  color: white;
  text-decoration: none;
}

.slogan {
  text-decoration: none;
  color: white;
  font-weight: 400;
  text-align: left;
}

/* mobile nav */
@media screen and (max-width: 1200px) {

  .logo {
    margin: 0px;
    width: 30px;
    height: 30px;

    border-radius: 10px;
    margin: 5px;
    border: 2px solid white;
    padding: 4px;
  }

  .phone {
    font-weight: 400;
  }

  .slogan {
    /* color: red; */
    padding: 0px;
    font-size: 16px;
    margin: 7px;
    /* margin-right: 10px; */
  }

  .menuButton {
    padding: 8px;
    font-size: 16px;
    margin: 7px;
  }
}

@media screen and (max-width: 800px) {
  .navWrapNormal {
    display: flex;
    z-index: 99999;
    top: 0;
    flex: 1 0 100%;
    width: 100%;
    max-width: 100%;
    margin: 0px;
    padding: 0px;
  }

  .navWrap {
    display: flex;
    z-index: 99999;
    top: 0;
    flex: 1 0 100%;
    width: 100%;
    max-width: 100%;
    margin: 0px;
    padding: 0px;
    /* border: 1px solid red; */
    position: fixed;
  }


  .content {

    padding-left: 2px;
    padding-right: 2px;

  }


  .content1 {
    padding-left: 2px;
    padding-right: 2px;
  }


  .phone {
    display: none;
  }

  .menuButton {
    font-size: 16px;
    padding: 10px;
    margin: 3px;
  }

  .slogan {
    margin-left: 15px;

    font-weight: 500;
    text-align: left;
  }

  .logo {
    height: 100%;
    margin: 1px;
  }



  .slogan,
  .menuButton {
    /* color: red; */
    font-size: 16px;
    margin: 3px;
    margin-right: 3px;
  }


}

.langButton {
  padding: 8px 12px;
  font-size: 14px;
  border-radius: 5px;
  border: 1px solid white;
  background-color: transparent;
  font-weight: bold;
  margin: 15px;
  color: white;
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: 6px;
  transition: all 0.3s ease;
}

.langButton:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.flagIcon {
  width: 20px;
  height: 20px;
  filter: grayscale(100%) brightness(100%);
  transition: all 0.3s ease;
}

.langButton:hover .flagIcon {
  filter: grayscale(0%);
}

/* Mobile adjustments */
@media screen and (max-width: 800px) {
  .langButton {
    /* Match menuButton mobile styles */
    font-size: 16px;
    padding: 10px;
    margin: 3px;
    margin-right: 3px;
  }

  .flagIcon {
    width: 16px;
    height: 16px;
  }

  /* Hide the text on very small screens if needed */
  @media screen and (max-width: 360px) {
    .langButton span {
      display: none;
    }
    
    .langButton {
      padding: 8px;
    }
  }
}