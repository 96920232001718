.featuresWrap {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  gap: 15px;
  margin: 5px;
  box-sizing: border-box;
}

.h2 {
  font-size: 18px;
  text-align: center;
}

.featureMap {
  align-self: center;
  box-sizing: border-box;
  align-self: center;
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  padding: 10px;
  margin: 10px;
  gap: 10px;
  box-sizing: border-box;
  width: 100%;
  max-width: 100%;
  /* border: 1px solid red; */
  align-self: center;
  border-radius: 10px;

}

.indivWrap {
  flex: 1 0 34%;
  display: flex;
  flex-direction: column;
}


.featureULItem {
  /* padding: 6.5px; */
  font-weight: bold;
  background-color: white;
  width: min-content;
  /* text-wrap: nowrap; */
  /* word-wrap: break-word; */
  border-radius: 5px;
  /* margin: 10px; */
  flex: 1 0 34%;
  max-width: 50%;
  height: 100%;
  align-self: flex-start;
  text-align: left;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  box-sizing: border-box;

  background: #334d50;
  /* fallback for old browsers */
  background: -webkit-linear-gradient(to left, #cbcaa5, #334d50);
  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to left, #cbcaa5, #334d50);
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

}



.featureP {
  background-color: white;
  margin: 4px;
  padding: 6px;
  width: 100%;
  border-radius: 4px;
  display: flex;
  align-items: center;
}

@media screen and (max-width: 1200px) {

  .featureP {
    min-height: 2rem;

  }

  .featureULItem {
    flex: 1 0 34%;
    box-sizing: border-box;
    /* text-wrap: nowrap; */
    height: 100%;
    max-width: 100%;
    /* height:3rem; */
  }

  .featureMap {
    box-sizing: border-box;
  }

}

@media screen and (max-width: 800px) {
  .featureULItem {
    flex: 1 0 49%;
    /* font-size: 2.5rem; */
    margin: 5px;
    /* text-wrap: nowrap; */
  }

  .featureP {
    min-height: auto;
  }

  .featureMap {
    gap: 5px;
    /* border: 1px solid black; */
    margin: 0px;
    width: 100%;
    max-width: 100%;
  }

  .featureULItem>* {
    font-size: 18px;
  }

  .featuresWrap {
    flex-direction: column;
    align-items: center;
  }

  .h2 {
    font-size: 18px;
    text-align: center;
  }
}