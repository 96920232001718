.contactForm {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  gap: 5px;
  margin: 5px;
  border-radius: 5px;
  background-color: white;
  padding: 5px;
  box-sizing: border-box;
  border-color: transparent;
}

.h2 {
  font-size: 18px;
  text-align: center;
}

.formWrap {
  background: linear-gradient(to right, rgb(40, 48, 72), rgb(133, 147, 152));
  max-width: 95vw;
  width: 600px;
  border-radius: 5px;
  align-self: center;
}

hr {
  margin: 5px;
  border: 1px solid #383e42b3;
  border-radius: 5px;
  flex: 1;
  align-self: center;
}

.contactForm>label {
  width: 100%;
  box-sizing: border-box;
  margin: 5px;
  padding: 5px;
  align-self: center;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.contactForm>label>input {
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 5px;
  border: 1px solid #383e42b3;
  box-sizing: border-box;
}

.contactForm>label>textarea {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #383e42b3;
  box-sizing: border-box;
}

.subButton {
  width: 50%;
  padding: 20px;
  margin: 20px;
  border-end-end-radius: 10px;
  border-top-left-radius: 10px;
  border-end-start-radius: 10px;
  align-self: center;
  font-weight: bold;
  cursor: pointer;
  color: white;
  border: 1px solid white;
  background: #334d50;
  background: -webkit-linear-gradient(to bottom, #cbcaa5, #334d50);
  background: linear-gradient(to bottom, #cbcaa5, #334d50);
  font-size: 1.5rem;
  box-shadow: 5px 5px 5px #383e42;
  transition: all 0.4s ease;
}

.subButton:hover {
  width: 70%;
}

.phoneNum {
  color: #383e42;
  background-color: transparent;
  padding: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 5px;
  text-align: center;
  height: 20px;
  border-radius: 5px;
  border: 1px solid rgb(192, 192, 192);
  align-self: center;
  text-decoration: none;
  flex: 1 0 34%;
}

.conWrap {
  display: flex;
  flex-direction: column;
  margin: 5px;
}

.contactWrap {
  display: flex;
  align-self: center;
  flex: 1 0 51%;
  width: 100%;
  max-width: 600px;
  align-self: center;
}

@media screen and (max-width: 800px) {
  .formWrap {
    flex: 1;
  }

  .phoneNum {
    font-size: 18px;
    font-weight: 700;
    letter-spacing: 1.5px;
    text-decoration: none;
    display: flex;
    width: 100%;
    flex-direction: column;
    flex: 1;
    box-sizing: border-box;
  }

  .contactWrap {
    max-width: none;
    margin: 10px;
    flex-direction: column;
    flex: 1;
    width: 100%;
    margin: 15px;
    box-sizing: border-box;
    text-align: center;
  }

  .contactForm>label {
    font-size: 18px;
  }

  .contactForm>label>input {
    height: 2rem;
    font-size: 18px;
  }

  .contactForm>label>textarea {
    height: 5rem;
    font-size: 18px;
  }

  .subButton {
    font-size: 18px;
  }
}