.footerWrap {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 80px;
  width: 100%;
  box-sizing: border-box;
  text-decoration: none;
  padding: 20px;
  flex-wrap: wrap;
  background: #334d50;
  /* fallback for old browsers */
  background: -webkit-linear-gradient(to left, #cbcaa5, #334d50);
  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to left, #cbcaa5, #334d50);
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.footerWrap>* {
  text-decoration: none;
  color: white;
  font-weight: 400;
}

.mapLink {
  text-decoration: underline;
}

.sellLandLink {
  color: #your-preferred-color;
  text-decoration: none;
  font-weight: bold;
  margin-bottom: 10px;
}

.sellLandLink:hover {
  text-decoration: underline;
}

@media screen and (max-width: 800px) {



  .footerWrap {
    flex-direction: column;
    align-items: flex-start;
    font-size: 16px;
  }
  .footerWrap>*{
    font-weight: 400;

  }


}